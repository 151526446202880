<template>
  <div class="flex flex-col mt-2 w-full">
    <div
      class="text-sm flex items-center mr-4 mb-1"
    >
      <Icon
        :name="passwordValid.uppercaseOrNumber ? 'check' : 'minus'"
        class="mr-3 w-6 h-6"
        :class="passwordValid.uppercaseOrNumber ? 'text-green-600' : (showErrors ? 'text-red-600' : 'text-gray-500')"
      />
      <span class="text-gray-700 text-sm font-medium">
        {{ useT('abc908') }}
      </span>
    </div>

    <div
      class="text-sm flex items-center mr-4"
      :class="passwordValid.minLength ? 'text-green-600' : (showErrors ? 'text-red-600' : 'text-gray-500')"
    >
      <Icon
        :name="passwordValid.minLength ? 'check' : 'minus'"
        class="mr-3 w-6 h-6"
        :class="passwordValid.minLength ? 'text-green-600' : (showErrors ? 'text-red-600' : 'text-gray-500')"
      />
      <span class="text-gray-700 text-sm font-medium">
        {{ useT('abc909') }}
      </span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  password: {
    type: String,
    required: true,
  },
  showErrors: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    required: true,
  },
});

const passwordValid = computed(() => {
  const minLength = props.rules.find((rule) => rule.strength === 'minLength');
  const uppercaseOrNumber = props.rules.find((rule) => rule.strength === 'uppercaseOrNumber');

  if (!minLength || !uppercaseOrNumber) {
    console.error('Password rules are not defined');
  }

  return {
    minLength: minLength?.pattern?.test(props.password),
    uppercaseOrNumber: uppercaseOrNumber?.pattern?.test(props.password),
  };
});
</script>
